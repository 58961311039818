import { defineStore } from 'pinia';
import { provideApolloClient } from '@vue/apollo-composable';
import { useI18n } from 'vue-i18n';
import { PRODUCT_MUTATION_UPDATE, PRODUCTS_QUERY_LIST, PRODUCTS_QUERY } from '../graphql';
import { useProduct } from '../composables/useProduct';
import type { Filter } from '~/components/ui/data/DataTable.vue';
import type { ProductsQueryListQuery, ProductsQueryQuery } from '~/types/graphql/graphql';

interface State {
  search: string;
  loading: boolean;
  products: ProductsQueryListQuery['products'];
  currentProduct: ProductsQueryQuery['products_by_pk'];
  error: null | string;
  filterBy: Filter | null;
  i18n: ReturnType<typeof useI18n>;
}

export const productStore = defineStore({
  id: 'productStore',
  state: (): State => ({
    search: '',
    loading: false,
    products: [],
    currentProduct: null,
    error: null,
    filterBy: {
      name: 'last-production',
      type: 'desc',
    },
    i18n: useI18n(),
  }),
  getters: {
    getFilter(): Filter | null {
      return this.filterBy;
    },
    getProducts(): ProductsQueryListQuery['products'] {
      return this.products.filter((product) => {
        return (
          product.label?.toLocaleLowerCase().includes(this.search.toLocaleLowerCase()) ||
          product.client_product_id.toString().includes(this.search)
        );
      });
    },
    getProduct() {
      return (id: number): ProductsQueryListQuery['products'][0] | undefined => {
        return this.products.find((product) => product.id === id);
      };
    },
    getProductFiltered(): ProductsQueryListQuery['products'] {
      const { getLastProductionToUnix, getAverageEPI } = useProduct();
      const data = this.getProducts;
      return data.sort((a, b) => {
        if (this.filterBy?.name === 'last-production') {
          if (this.filterBy.type === 'asc') return getLastProductionToUnix(a) - getLastProductionToUnix(b);
          if (!a.fabrication_order_products?.length) return 1;
          if (!b.fabrication_order_products?.length) return -1;
          return getLastProductionToUnix(b) - getLastProductionToUnix(a);
        }
        if (this.filterBy?.name === 'client-product-id') {
          if (this.filterBy.type === 'asc') return parseInt(a.client_product_id) - parseInt(b.client_product_id);
          return parseInt(b.client_product_id) - parseInt(a.client_product_id);
        }
        if (this.filterBy?.name === 'epi') {
          if (this.filterBy.type === 'asc') return getAverageEPI(a).average - getAverageEPI(b).average;
          return getAverageEPI(b).average - getAverageEPI(a).average;
        }
        return 0;
      });
    },
  },
  actions: {
    async fetchProducts() {
      this.loading = true;
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);

      try {
        const result = await apolloClient.query<ProductsQueryListQuery>({
          query: PRODUCTS_QUERY_LIST,
          variables: {
            site_id: globalStore().getSelectedSite,
          },
        });
        this.products = result.data.products;
      } catch (e: any) {
        this.error = e.message;
      }

      this.loading = false;
    },
    async fetchProduct(id: number) {
      this.loading = true;

      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);

      try {
        const result = await apolloClient.query<ProductsQueryQuery>({
          query: PRODUCTS_QUERY,
          variables: {
            id: id,
          },
        });
        this.currentProduct = result.data.products_by_pk;
      } catch (e: any) {
        this.error = e.message;
      }

      this.loading = false;
    },
    async updateProduct(id: number, product: { label?: string; epi_standard?: number }) {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);

      try {
        await apolloClient.mutate({
          mutation: PRODUCT_MUTATION_UPDATE,
          variables: {
            id: id,
            object: product,
          },
        });
      } catch (e: any) {
        // Show toast
        toastStore().showToast({
          type: 'error',
          message: this.i18n.t('global.error'),
          title: this.i18n.t('product.update.error'),
        });
        this.error = e.message;
      }
      this.fetchProducts();
    },
    updateFilter(filter: Filter | null) {
      this.filterBy = filter;
    },
  },
});
